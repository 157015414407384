import { computed, ref, onMounted } from "vue";
import { useMouseInElement, templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  buttons: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props


const { reveal } = useScrollTriggerAnimation();
const tpl_btnGroup = templateRef("tpl_btnGroup");
const tpl_btnGroupInner = templateRef("tpl_btnGroupInner");
const tpl_swiper = templateRef("tpl_swiper");
const avatarSwiper = ref();
const showAvatar = ref(true);
const { elementX, elementY, elementWidth, elementHeight } =
  useMouseInElement(tpl_btnGroupInner);

const cachedAvatarTranslate = ref(
  `translate3d(${elementWidth.value / 2}px, ${
    elementHeight.value / 2 - 16
  }px, 0px`
);

const avatarPos = computed(() => {
  cachedAvatarTranslate.value = `translate3d(${elementX.value}px, ${
    elementY.value - 16
  }px, 0px)`;

  return `width: 200px; height: 250px; transform: ${cachedAvatarTranslate.value};`;
});

function handleMouseEnter(index) {
  if (avatarSwiper.value) {
    avatarSwiper.value.slideTo(index, 0, false);
    showAvatar.value = true;
  }
}

function handleMouseLeave() {
  showAvatar.value = false;
}

function handleClick(index) {}

onMounted(() => {
  avatarSwiper.value = new Swiper(tpl_swiper.value, {
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
  });

  showAvatar.value = false;

  requestAnimationFrame(() => {
    reveal(tpl_btnGroupInner.value, tpl_btnGroup.value, "bottom", {
      intensity: "strong",
      fade: true,
    });
  });
});

return { __sfc: true,props, reveal, tpl_btnGroup, tpl_btnGroupInner, tpl_swiper, avatarSwiper, showAvatar, elementX, elementY, elementWidth, elementHeight, cachedAvatarTranslate, avatarPos, handleMouseEnter, handleMouseLeave, handleClick }
}

}