import { render, staticRenderFns } from "./index.vue?vue&type=template&id=53cdc8ff&"
import script from "./index.vue?vue&type=script&setup=true&lang=js&"
export * from "./index.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TxtHuge: require('/var/www/html/fantini/frontend-2/components/txt/huge/index.vue').default,GridCss: require('/var/www/html/fantini/frontend-2/components/grid/css/index.vue').default,Block: require('/var/www/html/fantini/frontend-2/components/block/index.vue').default})
